<template>
	<section class="main">
		<div class="hotel" style="height: 100vh">
			<a id="bg-park" class="sliderBG" />
			<div class="hotelInfo">
				<img src="~/assets/images/logo_white_park.png" alt="Amadi Park Hotel" />
				<p>
					<strong>Amadi Park Hotel</strong><br />
					Eerste Constantijn Huygensstraat 26-G<br />
					1054 BR Amsterdam<br />
					+31 (0) 20 3080570<br />
					<a href="mailto:park@amadihotels.com">park@amadihotels.com</a>
				</p>
			</div>
			<div class="reserve">
				<a href="https://park.amadihotels.com/" target="_blank" class="button book visit">
					{{ $t('visitWebsite') }}</a
				>
				<a
					target="_blank"
					href="https://be.synxis.com/?Chain=5375&Hotel=59516&Locale=en-GB&template=AMA&shell=AMA&Dest=AMA"
					class="book_park button book"
				>
					{{ $t('bookNow') }}
				</a>
			</div>
		</div>

		<div class="hotel" style="height: 100vh">
			<a id="bg-panorama" class="sliderBG" />
			<div class="hotelInfo">
				<img src="~/assets/images/logo_white_panorama.png" alt="Amadi Panorama Hotel" />
				<p>
					<strong>Amadi Panorama Hotel</strong><br />
					IJburglaan 539<br />
					1087 BE Amsterdam<br />
					+31 (0) 20 2626900<br />
					<a href="mailto:panorama@amadihotels.com">panorama@amadihotels.com</a>
				</p>
			</div>
			<div class="reserve">
				<a href="https://panorama.amadihotels.com" target="_blank" class="button book visit">{{
					$t('visitWebsite')
				}}</a>
				<a
					target="_blank"
					href="https://be.synxis.com/?Chain=5375&Hotel=63686&Locale=en-GB&template=AMA&shell=AMA&Dest=AMA"
					class="book_panorama button book"
					>{{ $t('bookNow') }}
				</a>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: 'IndexPage',
};
</script>

<style lang="scss" scoped>
#bg-park {
	background-image: url('~/assets/images/header1.jpg');
}

#bg-panorama {
	background-image: url('~/assets/images/header2.jpg');
}

section.main {
	padding: 0;
}
</style>
